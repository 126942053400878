<template>
  <div class="panel login-panel">
    <div class="panel-head">{{title}}</div>
    <div class="panel-body">
      <el-form ref="forgetForm" :rules="rules" :model="model" method="post" id="forgetForm"
               onsubmit="return false" label-width="80px">
        <el-form-item prop="loginAccount" label="登录账号">
          <el-input v-model="model.loginAccount" placeholder="登录账号"></el-input>
        </el-form-item>

        <el-form-item label="验证码" prop="_captcha">
          <el-input :maxlength="4" placeholder="验证码" v-model="model._captcha" style="width: 100px"></el-input>
          <captcha-image ref="captcha-image"/>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" :loading="locked" @click="submitForm('forgetForm')">下一步</el-button>
          <router-link :to="{name:'authLogin'}" class="pull-right">返回登录</router-link>
        </el-form-item>
      </el-form>
      <div class="clear"></div>
    </div>
    <verify-forget-phone :visible.sync="dialogVisible" :phone="model.loginAccount"/>
  </div>
</template>
<script>
  import CaptchaImage from '../common/CaptchaImage'
  import VerifyForgetPhone from './VerifyForgetPhone'

  export default {
    name: 'AuthForget',
    components: {VerifyForgetPhone, CaptchaImage},
    data: function () {
      return {
        title: '忘记登录密码',
        model: {
          loginAccount: '',
          _captcha: ''
        },
        locked: false,
        rules: {
          loginAccount: [
            {required: true, message: '请输入登录账号', 'trigger': 'blur'},
            {max: 36, message: '登录账号长度超过限制', 'trigger': 'blur'}
          ],
          _captcha: [
            {required: true, message: '请输入验证码', 'trigger': 'blur'}
          ]
        },
        dialogVisible: false
      }
    },
    methods: {
      submitForm (formName) {
        const self = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            self.locked = true
            self.$http.post({
              url: '/auth/checkForget.action',
              data: self.model,
              success () {
                self.dialogVisible = true
              },
              fail (response) {
                self.$refs['captcha-image'].changeImg()
                self.$http.errorHandle(response)
              },
              always () {
                self.locked = false
              }
            })
          } else {
            return false;
          }
        })
      }
    }
  }
</script>
<style scoped>
  .login-panel {
    height: 260px;
    margin-top: -130px;
  }


</style>
