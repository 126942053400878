<template>
  <verify-phone-dialog :rules="rules"
                       :model="model"
                       :visible="visible"
                       :phone="phone"
                       @close="$emit('update:visible', false)"
                       button-text="重置密码"
                       send-phone-url="/auth/sendCodeForResetPassword.action"
                       submit-url="/auth/resetPassword.action"
                       @success="success"
  >

    <el-form-item prop="verifyCode" label="验证码">
      <el-input v-model="model.verifyCode"
                placeholder="请输入邮件中收到的验证码"
                type="text"
                style="width: 200px"
      />
    </el-form-item>

    <el-form-item label="登录密码" prop="loginPassword">
      <el-input v-model="model.loginPassword" type="password" placeholder="请输入新登录密码"></el-input>
    </el-form-item>

    <el-form-item label="确认密码" prop="confirmPassword">
      <el-input v-model="model.confirmPassword" type="password" placeholder="请再次输入登录密码"></el-input>
    </el-form-item>


  </verify-phone-dialog>
</template>

<script>
  import VerifyPhoneDialog from './VerifyPhoneDialog'

  export default {
    name: "VerifyForgetPhone",
    components: {VerifyPhoneDialog},
    data () {
      const self = this
      return {
        model: {
          verifyCode: '',
          loginPassword: '',
          confirmPassword: '',
          loginAccount: ''
        },
        rules: {
          verifyCode: [
            {required: true, message: '请输入验证码', 'trigger': 'blur'},
            {min: 6, max: 6, message: '验证码错误', 'trigger': 'blur'}
          ],
          loginPassword: [
            {required: true, message: '请输入新登录密码', 'trigger': 'blur'},
            {min: 6, max: 16, message: '密码长度范围为6-16个字符', 'trigger': 'blur'}
          ],
          confirmPassword: [
            {required: true, message: '请再次输入登录密码', 'trigger': 'blur'},
            {
              validator (rule, value, callback) {
                if (value !== self.model.loginPassword) {
                  callback(new Error('两次密码输入不一致'))
                } else {
                  callback()
                }
              },
              'trigger': 'blur'
            }
          ]
        }
      }
    },
    props: {
      phone: String,
      visible: Boolean
    },
    watch: {
      phone () {
        this.model.loginAccount = this.phone
      }
    },
    methods: {
      success () {
        this.$message.success('密码重置成功')
        this.$router.push({name: 'authLogin'})
      }
    }
  }
</script>

<style scoped>

</style>